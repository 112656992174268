.billdoc-content {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: #555555;
  background: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 1.1rem;
  // font-family: SourceSansPro;
}

.clearfix {
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

#billdoclogo {
  float: left;
  margin-top: 8px;
}

.receiptheader {
  margin-bottom: 8px;
  color: #777777;
  font-family: inherit;
  font-size: 1.2em;
}

#billdoclogo img {
  height: 2.5rem;
}

#billdoccompany {
  float: right;
  text-align: right;
}

#billdocdetails {
  margin-bottom: 30px;
}

#billdocclient {
  padding-left: 6px;
  // border-left: 6px solid #0087c3;
  float: left;
}

#billdocclient .to {
  color: #777777;
  text-transform: uppercase;
}

h2.billdoc-cust-name,
h2.billdoc-org-name {
  font-size: 1.1em;
  font-weight: normal;
  margin: 0;
}

#billdocinvoice {
  float: right;
  text-align: right;
}

#billdocinvoice h1 {
  // color: #0087c3;
  font-size: 1.1em;
  line-height: 1em;
  font-weight: normal;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

#billdocinvoice .date,
#billdocinvoice .method {
  // font-size: 1.1em;
  color: #777777;
}

#billdoctable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

#billdoctable th,
#billdoctable td {
  padding: 9px; // to adjust the table cell height
  background: #eeeeee;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}

#billdoctable th {
  white-space: nowrap;
  font-weight: bold;
  text-transform: uppercase;
}

#billdoctable td {
  text-align: right;
}

#billdoctable td h3 {
  // color: #57b223;
  // font-size: 1.2em;
  font-weight: normal;
  margin: 0 0 0.2em 0;
}

#billdoctable .no {
  // color: #ffffff;
  // font-size: 1.6em;
  text-align: center;
}

#billdoctable .id {
  text-align: center;
  background: #dddddd;
}

#billdoctable tbody .desc {
  text-align: left;
}

#billdoctable .qty {
  text-align: center;
  background: #dddddd;
}

#billdoctable tbody .notes {
  text-align: left;
}

#billdoctable .total {
  background: #e3eefa;
  // color: #ffffff;
  text-align: right;
}

#billdoctable tbody tr:last-child td {
  border: none;
}

#billdoctable tfoot td {
  padding: 10px 20px;
  background: #ffffff;
  border-bottom: 0.5px solid #aaaaaa;
  // font-size: 1.1em;
  white-space: nowrap;
  border-top: none;
  text-transform: capitalize;
}

#billdoctable tfoot .billdocgrandtotal td {
  // color: #57b223;
  // font-size: 1.1em;
  // border-top: 1px solid;
  border-bottom: 0.5px solid;
}

// #billdoctable tfoot tr:first-child td {
//   border-top: none;
// }

// remove the border for the first column for the subtotal/grand total area
#billdoctable tfoot tr td:first-child {
  border: none;
}

#billdocthanks {
  font-size: 1.2em;
  margin-bottom: 30px;
}

#billdocnotices {
  padding-bottom: 6px;
  // border-left: 6px solid #0087c3;
}

#billdocnotices .notice,
#billdocnotices .terms {
  font-size: 0.6em;
  text-align: justify;
}

#billdocfooter {
  color: #777777;
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0;
  border-top: 0.5px solid #aaaaaa;
  padding: 8px 0;
  text-align: center;
}
