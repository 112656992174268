/* All buttons (with .btn class) attributes */

%btn {
  background-image: $gradient;
  border-radius: 10rem;
  border: none;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }

  // & > *:first-child {
  //   margin-right: 0.3rem;
  // }
}

.btn {
  @extend %btn;

  padding: 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  background-image: none;
  background-color: #fff;

  svg {
    height: 2rem;
    width: 2rem;
  }
}

.link:link,
.link:visited {
  color: $color-grey-dark-2;
}

.spinner {
  position: fixed;
  top: 40%;
  left: 50%;

  svg {
    height: 4rem;
    width: 4rem;
    fill: #08d;
    animation: rotate 2s infinite linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.message,
.error {
  max-width: 40rem;
  margin: 0 auto;
  padding: 2rem 2rem;

  display: flex;

  svg {
    height: 3rem;
    width: 3rem;
    fill: $color-primary;
    transform: translateY(-0.3rem);
  }

  p {
    margin-left: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 600;
  }
}
