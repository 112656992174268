/* Variables assignment */
// $color-primary: #f59a83;
// $color-primary: #f38e82;
$color-primary: #3863be;
$color-grad-1: #fbdb89;
$color-grad-2: #f48982;

$color-grey-light-1: #f9f5f3; // Light background
$color-grey-light-2: #f2efee; // Light lines
$color-grey-light-3: #d3c7c3; // Light text (placeholder)
$color-grey-light-4: #b3b3b3;
$color-grey-dark-1: #615551; // Normal text
$color-grey-dark-2: #918581; // Lighter text
$color-light-blue: #e3eefa; //light blue
$color-blue: #5068f0; //blue
$color-black: #101010; //black

$gradient: linear-gradient(to right bottom, $color-grad-1, $color-grad-2);

// $bp-xlarge: 90em; // 1920px
$bp-large: 78.15em; // 1250px
$bp-medium: 61.25em; // 980px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

/* Standard best practice */
* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media only screen and (max-width: $bp-medium) {
    font-size: 60%;
  }
}

/* Body and Container attributes, including responsive grids */
.body__login {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  align-items: center;
  background-color: #000;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 2 * 4vw);
}

.body__main {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: $color-grey-dark-1;
  background-color: #f5eeee;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 2 * 4vw);
}

.container__login {
  max-width: 190rem;
  margin: 1vw auto;
  background-color: #15172b;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 0 2rem 6rem 0.5rem rgba($color-grey-dark-1, 0.2);
}

.container__main {
  max-width: 190rem;
  min-height: calc(100vh - 2 * 4vw);
  margin: 1vw auto;
  background-color: #fff;
  border-radius: 9px;
  // overflow: hidden;
  // overflow-y: auto;
  box-shadow: 0 2rem 6rem 0.5rem rgba($color-grey-dark-1, 0.2);
  display: grid;
  grid-template-rows: 6rem 2rem auto minmax(51rem, auto);
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 0.4rem;
  // grid-template-areas:
  //   'head'
  //   'list';

  /* Remove the container margin and border when the viewport is smaller than 1250px */
  @media only screen and (max-width: $bp-large) {
    max-width: 100%;
    margin: 0;
    border-radius: 0;
  }
}
