/* Make elements within header row to be flexible and centered vertically */
.header {
  // grid-area: head;
  background-color: $color-grey-light-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: -webkit-sticky; /* Safari */
  position: sticky; /* does not work in IE */
  top: 0;

  /* header > logo attributes. Fix the left/right margin and height, and display block so that it will not shrink in size */
  &__logo {
    margin-left: 2rem;
    margin-right: 1rem;
    height: 2.2rem; //original is 2.2rem, change to 5.2rem for J
    display: block;

    @media only screen and (max-width: $bp-smallest) {
      height: 1.7rem;
      margin-left: 1rem;
      margin-right: 0.8rem;
    }
  }
}

/* header > search field and btn attributes. Display flex so that it will shrink in size as viewport shrinks */
.search {
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  transition: all 0.3s;
  position: relative;

  /* header > search with pointer focus. Move the search field and btn up slightly when user clicks within it */
  &:focus-within {
    transform: translateY(-2px);
    box-shadow: 0 0.7rem 3rem rgba($color-grey-dark-1, 0.08);
  }

  /* header > search field attributes. */
  &__field {
    border: none;
    background: none;
    font-family: inherit;
    color: inherit;
    font-size: 1.3rem;
    width: 28rem;

    /* header > search field. Remove the default outline for input type */
    &:focus {
      outline: none;
    }

    /* header > search field placeholder text color */
    &::placeholder {
      color: $color-grey-light-3;
    }

    @media only screen and (max-width: $bp-large) {
      width: 26rem;
    }
    /* header > search field. Remove the placeholder text and shrink the width according to viewport when it is less than 980px */
    @media only screen and (max-width: $bp-medium) {
      width: auto;

      &::placeholder {
        color: white;
        display: none;
      }
    }

    /* When it is less than 500px */
    @media only screen and (max-width: $bp-smallest) {
      width: 6.5rem;
    }
  }

  /* header > search button attributes */
  &__btn {
    font-weight: 600;
    font-family: inherit;
  }
}

/* header > navigation list attributes. align-self (for individual items in flexbox, in this case the nav) aligns the nav items on the cross axis in flexbox. For horizonal flexbox (flex-direction:row), it aligns vertically, For vertical flexbox (flex-direction:column), it aligns horizontally. */
.nav {
  // margin-right: 2.5rem;
  align-self: stretch;
  display: flex;

  /* header > nav list attributes. Remove the default list-style and display flex the items in nav list. Make the height 100% within the row height. */
  &__list {
    list-style: none;
    display: flex;
    height: 100%;
  }

  /* header > nav item attributes. Change the background color on hover over the nav item */
  &__item {
    position: relative;
    &:hover {
      background-color: $color-grey-light-2;
    }
  }

  /* header > master data dropdown menu attributes. Set the background color with shadow to enable visibility of the dropdown box. z-index so that it appears in front of all elements. Hide the dropbox first. */
  &__dropdown {
    display: none;
    background-color: $color-grey-light-1;
    box-shadow: 0 0.7rem 3rem rgba($color-grey-dark-1, 0.08);
    z-index: 10;

    /* header > master data dropdown attributes. Show the dropdown menu on hover over master data menu, and continue to show the dropmenu when hover over the dropdown box  */
    &:hover,
    .nav__btn--masterdata:hover + & {
      display: block;
    }

    &:hover,
    .nav__btn--profile:hover + & {
      display: block;
    }

    &:hover,
    .nav__btn--package:hover + & {
      display: block;
    }

    &:hover,
    .nav__btn--pos:hover + & {
      display: block;
    }

    &:hover,
    .nav__btn--finance:hover + & {
      display: block;
    }
  }

  /* header > nav buttons attributes. */
  &__btn,
  &__btn--apps {
    height: 100%;
    font-family: inherit;
    color: inherit;
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: capitalize;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 0.8rem;
    transition: all 0.3s;
    display: flex;
    align-items: center;

    /* header > nav dropdown box attributes. Set a min height and 100% width so that elements in dropdown box are spaced out vertically and with full width as its parent  . */
    &--dropdown {
      min-height: 5rem;
      width: 100%;
    }

    /* header > all nav buttons svg attributes. The transform is to move the svg position slightly higher */
    svg {
      height: 2.2rem;
      width: 2.2rem;
      fill: $color-primary;
      margin-right: 0.5rem;
      transform: translateY(-1px);
    }

    /* Remove default outline */
    &:focus {
      outline: none;
    }

    /* Change the background color on hover over the button. This is needed as the dropdown menu items are not nav items */
    &:hover {
      background-color: $color-grey-light-2;
    }
  }

  &__profile {
    font-family: inherit;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    background: $color-primary;
    font-size: 1.8rem;
    color: #fff;
    text-align: center;
    line-height: 4.5rem;
    // margin: 2rem 0;
    &-name {
      text-transform: capitalize;
      display: none;
      font-family: inherit;
    }
  }

  &__chart-name,
  &__cart-name {
    text-transform: uppercase;
    display: none;
    font-family: inherit;
  }
}

/* related to nav filters*/

/* this is to ensure the search box and filter dropdown are in one column*/
.filter__block {
  position: relative;
  align-self: stretch; //this is needed to ensure that the first element occupies the entire header
}

/* this is to ensure the search box and filter button are adjacent to each other*/
.filter__items {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav__btn--filterlist,
.nav__btn--camera {
  border-radius: 0;
  border-color: #f2efee;
  border-style: solid;
  border-width: 0.5px;
}

/* header > app dropdown box attributes. This is hidden at first*/
.filter__box {
  z-index: 10;
  background-color: #fff;
  border: none;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 8px;
  display: none;
  max-height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.5s 0.2s;

  & label {
    font-size: 1.4rem;
    font-weight: 600;
    color: inherit;
    vertical-align: middle;
    display: inline;
    margin-right: 0.5rem;

    /* When it is less than 980px */
    @media only screen and (max-width: $bp-medium) {
      font-size: 1.3rem;
    }
    /* When it is less than 500px */
    @media only screen and (max-width: $bp-smallest) {
      display: none;
    }
  }

  & input,
  & select,
  & select > option {
    color: inherit;
    font-size: 1.4rem;
    font-family: inherit;
    padding: 0.8rem 1rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    transition: all 0.2s;
    vertical-align: middle;
    margin-right: 1%;

    &::placeholder {
      color: $color-grey-light-3;
    }

    &:focus {
      outline: none;
      border: 1px solid $color-blue;
      background-color: $color-grey-light-1;
    }

    /* When it is less than 980px */
    @media only screen and (max-width: $bp-medium) {
      font-size: 1.3rem;
    }
  }
}

.filter__list {
  list-style: none;
  height: 100%;
  width: 100%;
}

.filter {
  list-style: none;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
}

.filter__daterange {
  /* When it is less than 500px */
  @media only screen and (max-width: $bp-smallest) {
    display: block;
  }
}

/* header > app dropdown box attributes. This is hidden at first*/
.appbox {
  position: absolute;
  right: 0.5rem;
  z-index: 10;
  background-color: #fff;
  border-radius: 26px;
  border: none;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 8px;
  display: none;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.5s 0.2s;

  /* Show the app dropdown box on hover over the  */
  &:hover,
  .nav__btn--apps:hover + & {
    display: block;
  }
}

.apps {
  display: inline-block;
  height: 84px;
  list-style-type: none;
  padding: 6px;
  position: relative;
  transition: transform 0.2s cubic-bezier(0.333, 0, 0, 1);
  vertical-align: top;
  width: 84px;
}

.appitem {
  border-radius: 8px;
  margin: 0;
  outline: none;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 80px;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-light-1;
  }
}

.appitembox {
  height: 45px;
  margin-top: 5px;
}

.applogo {
  display: inline-block;
  height: 40px;
  vertical-align: top;
  width: 40px;
  background-size: 40px 40px;
}

.applogo1 {
  background-image: url(../img/edate_logo.png);
}

.ifr {
  display: none;
}

/* header > hamburger attributes. This is hidden at first which also hides the 3 bars*/
.hamburger {
  display: none;
}

/* header > hamburger bars attributes. */
.bar {
  display: block;
  width: 20px;
  height: 3px;
  margin: 4px auto;
  transition: all 0.3s ease-in-out;
  background-color: $color-black;
}

/* header > nav list attributes. Hide the navigation menu list when viewport is less than 900px. Fix the position by moving them to the right with 0 margin*/
@media only screen and (max-width: $bp-medium) {
  .nav__list {
    position: fixed;
    display: none;
    right: 0;
    top: 6rem;
    background-color: $color-grey-light-1;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  /* header > nav list attributes (<900px). Show the navigation menu list upon click of hamburger menu (performed by JS to add "active" class). Display them in block so that it appears vertically with right 0 margin*/
  .nav__list.active {
    right: 0;
    display: block;
  }

  /* header > nav item attributes (<900px). Set 0 margin so that the hover shade covers the entire menu row. Set miminum height so that it is spaced out vertically. Set padding so that the words are centered. */
  .nav__item {
    margin: 0;
    min-height: 5rem;
    padding-top: 0.8rem;
    padding-left: 0.8rem;
  }

  /* header > user initials and name to change to display inline when hamburger appears*/
  .nav__profile {
    width: 3rem;
    height: 3rem;
    font-size: 1.6rem;
    line-height: 3rem;
    &-name {
      display: inline;
      margin-left: 0.6rem;
      font-size: 1.4rem;
    }
  }

  .nav__chart-name,
  .nav__cart-name {
    display: inline;
    font-size: 1.3rem;
  }

  .cart-count {
    left: 75px;
  }

  /* header > hamburger attributes. Show the hamburger 3 bars menu.*/
  .hamburger {
    display: block;
    cursor: pointer;
    margin-top: 2rem;
    padding-left: 2rem;
  }

  /* header > hamburger attributes. Animate the 3 bars.*/
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }
}

@media only screen and (max-width: $bp-smallest) {
  .appbox {
    right: 0rem;
  }
}
