.filter-container {
  position: fixed;
  top: 110px;
  right: 0;
  height: 90vh;

  //   display: flex;
  //   align-items: center;
}

.filter-panel {
  display: flex;
  //   align-items: center;
  height: 90vh;
  background: white;
  border-left: 1px solid #ddd;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: -200px; // reinstate this after test
  // left: -200px; // delete this after test
  width: 200px;
  transition: right 0.8s ease;
  flex-direction: row;
  //   justify-content: center;
  padding: 20px;
}

.filter-container:hover .filter-panel {
  right: 0;
}

.filter-button {
  background: $color-grey-light-1;
  //   background: blue;
  font-family: inherit;
  font-weight: 600;
  color: $color-grey-dark-1;
  padding: 4px 25px 4px 25px;
  cursor: pointer;
  border: none;
  border-radius: 0px 0px 10px 10px;
  font-size: 14px;
  position: absolute;
  top: 40%;
  left: -55px;
  transition: left 0.3s ease;
  transform: rotate(90deg);
  transform-origin: center;
}

.filter-container:hover .filter-button {
  left: -40px;
}

.filter-saleschart,
.filter-billchart {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  align-items: start;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  &__column {
    display: block;
    align-items: center;
    gap: 1.5rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    & label {
      font-size: 1.3rem;
      font-weight: 600;
      color: inherit;
      // vertical-align: middle;
      display: block;
    }

    & input,
    & select,
    & select > option {
      color: inherit;
      font-size: 1.3rem;
      font-family: inherit;
      padding: 0.8rem 1rem;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      transition: all 0.2s;
      vertical-align: middle;
      margin-right: 1%;

      &::placeholder {
        color: $color-grey-light-3;
      }

      &:focus {
        outline: none;
        border: 1px solid $color-blue;
        background-color: $color-grey-light-1;
      }
    }

    //   & button {
    // 	grid-column: 1 / span 2;
    // 	justify-self: center;
    // 	margin-top: 1rem;
    //   }
  }

  // &__heading {
  //   font-size: 1.5rem;
  //   font-weight: 700;
  //   text-transform: uppercase;
  //   margin-bottom: 1rem;
  //   grid-column: 1/-1;
  // }

  // &__btn {
  //   grid-column: 1 / -1; // to remove the columns so that the button can be centered
  //   justify-self: center;
  // }
}
