.shop-content {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(220px, auto)
  ); // 1st parameter refers to the number of columns, in this case allow auto-fit across the entire width based on min width of 220px for each column and max via auto
  // gap: 1.5rem;
  justify-content: center;
  align-content: center;
  margin-bottom: 10px;
}
.item-box {
  position: relative;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(40, 37, 37, 0.1);
  border-radius: 3px;
}

.item-pic {
  overflow: hidden;
  min-height: 88%;
  cursor: pointer;
}

.item-pic:hover img {
  transform: scale(1.5);
}

.item-img {
  transition: 0.4s;
  // aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  height: 320px;
}

.popup-flying-img {
  position: absolute;
  animation: fly_to_cart 1s ease-in-out;
  z-index: 80; // this is needed to show the animation on top of all other elements
}

@keyframes fly_to_cart {
  0% {
    left: 0;
    top: 0;
    // transform: scale(0.5);
  }
  45%,
  50% {
    left: var(--left);
    top: 60px;
    // transform: scale(0.2);
  }
  80%,
  90% {
    left: var(--left);
    top: var(--top);
    transform: scale(0.05);
  }
  100% {
    left: var(--left);
    top: var(--top);
    transform: scale(0.05);
  }
}

.item-title-qty {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.item-qty,
.cart-item-stock-qty {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: inherit;
  color: #969696;
}

.item-title {
  font-size: 1.4rem;
  font-weight: 600;
  font-family: inherit;
  color: inherit;
  text-transform: uppercase;
  // color: #000000;
}

.popup-content,
.popup-camera {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60rem;
  height: 100%;
  background-color: white;
  border-radius: 9px;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
  z-index: 50;
  transition: all 0.5s;
  text-align: center;
  overflow-y: auto;

  svg {
    height: 3rem;
    width: 3rem;
    fill: $color-primary;
    margin-right: 0.7rem;
    transform: translateY(-1px);
    display: inline;
    vertical-align: middle;
  }

  @media only screen and (max-width: $bp-medium) {
    width: auto;
  }
  @media only screen and (max-width: $bp-smallest) {
    width: 100%;
    height: 90%;
  }
}

.popup-camera-video {
  width: 100%;
  height: 100%;
  transform: scaleX(-1); /* Mirrors the video horizontally */
}

.popup-item-pic {
  height: 600px;
  width: 100%;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.popup-item-pic::-webkit-scrollbar {
  width: 2px;
}

.popup-item-pic::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.popup-item-pic::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.popup-item-img {
  transition: 0.4s;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.section {
  height: 10px;
}

.popup-item-title {
  font-size: 1.6rem;
  font-weight: 800;
  font-family: inherit;
  color: inherit;
  text-transform: uppercase;
  text-align: left;
  margin-top: 1.6rem;
}

.popup-item-description {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: inherit;
  color: inherit;
  text-align: left;
  text-transform: none;
}

.item-price-area,
.cart-price-area,
.popup-item-price-area {
  display: flex;
}

.item-discount,
.popup-item-discount {
  display: flex;
  background-color: black;
  color: #fff;
}

.cart-discount {
  background-color: black;
  color: #fff;
}

.item-sellingprice {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: inherit;
  color: inherit;
}

.item-discountsellingprice,
.popup-item-discountsellingprice {
  margin-right: 0.8rem;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: inherit;
  color: inherit;
}

.item-originalprice,
.cart-origprice,
.popup-item-originalprice {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: inherit;
  color: inherit;
  text-decoration: line-through;
  margin-right: 0.5rem;
}

.popup-item-price {
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: inherit;
  color: inherit;
  text-align: left;
}

.popup-item-code {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: inherit;
  color: inherit;
  text-align: left;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.add-cart {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  transition: 0.5s;
  height: 3rem;
  width: 3rem;
  z-index: 10;
  svg {
    height: 2.2rem;
    width: 2.2rem;
    fill: $color-primary;
  }
}

.add-cart svg:hover {
  fill: #0096ff;
  transition: 0.5s;
  transform: translateY(-2px);
}

.cart-count {
  position: absolute;
  color: white;
  background-color: #ce1717;
  font-family: inherit;
  font-size: 1.2rem;
  text-align: center;
  top: 0.5px;
  right: 12px;
  padding: 0.5px;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  z-index: 5;
  display: none; // set to none initially
}

.cart {
  position: fixed;
  top: 80px;
  right: -100%;
  width: 450px;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  background-color: white;
  box-shadow: 0 1px 4px rgba(40, 37, 37, 0.1);
  z-index: 5;
  transition: 1s;
}

.cart-active {
  right: 0;
  transition: 0.5s;
}

.cart-box {
  display: grid;
  grid-template-columns: 28% 58% 16%;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.cart-checkoutbox {
  display: grid;
  grid-template-columns: 28% 70%;
  align-items: center;
  margin-top: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.cart-top {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  // padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cart-title {
  // text-align: center;
  font-size: 1.8rem;
  font-weight: 800;
  // margin-bottom: 1rem;
  // padding-bottom: 20px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cart-camera {
  border-radius: 0;
  border-color: #f2efee;
  // border-style: solid;
  // border-width: 0.5px;
}

.cart-img,
.cart-checkout-img {
  width: 75px;
  height: 98px;
  object-fit: cover;
  // border: 2px solid rgba(0, 0, 0, 0.1);
  // padding: 5px;
}

.cart-detail-box {
  display: grid;
  row-gap: 0.5rem;
}

.cart-price-box,
.cart-checkout-price-box {
  display: flex;
  justify-content: space-between;
}

.cart-item-attribute {
  display: inline-flex;
}

.cart-item-title,
.cart-item-size,
.cart-item-color,
.cart-item-separator,
.cart-checkout-item-title,
.cart-checkout-item-size {
  text-transform: uppercase;
  color: inherit;
  font-size: 1.3rem;
  // font-weight: 500;
}

.cartcheckout-orderno {
  color: inherit;
  font-size: 1.3rem;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  // padding-bottom: 16px;
  padding-left: 6px;
}

.cartcheckout-custname {
  text-transform: uppercase;
  font-size: 1.3rem;
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  padding-bottom: 16px;
}

.cartcheckout-orderno-row {
  display: inline-flex;
}

.cart-item-size,
.cart-item-color,
.cart-item-separator {
  margin-right: 0.5rem;
}

.cart-price,
.cart-amt,
.cart-checkout-amt,
.cart-checkout-quantity {
  font-size: 1.4rem;
  font-weight: 500;
}

.cart-price {
  display: flex;
}

.delete-cart {
  cursor: pointer;
  svg {
    height: 2.5rem;
    width: 2.5rem;
    fill: $color-primary;
  }
}

.delete-cart svg:hover {
  fill: #0096ff;
  transition: 0.5s;
  transform: translateY(-2px);
}

.cartcheckout-total,
.cart-total {
  display: grid;
  grid-template-columns: 58% 28%;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.cart-total-title-col,
.cart-total-price-col,
.cartcheckout-total-title-col,
.cartcheckout-total-price-col {
  text-align: end;
}

.cart-subtotal-title,
.cart-tax-title,
.cart-total-title,
.cartcheckout-ordertotal-title,
.cartcheckout-tax-title,
.cartcheckout-grandtotal-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 0.8rem;
}

.cart-subtotal-price,
.cart-tax-price,
.cart-total-price,
.cartcheckout-ordertotal-price,
.cartcheckout-tax-price,
.cartcheckout-grandtotal-price {
  margin-top: 0.8rem;
  font-size: 1.5rem;
}

.btn--close-modal-cart {
  // position: absolute;
  // top: 1rem;
  // right: 0.8rem;
  font-size: 3.5rem;
  border: none;
  background: none;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
}

//To customize all number input type to plus and minus type

// to remove the spin up/down arrows on input number type
.input-number-group input[type='number']::-webkit-inner-spin-button,
.input-number-group input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-number-group input[type='button'] {
  background-color: #eeeeee;
  min-width: 38px;
  width: auto;
  transition: all 300ms ease;
  cursor: pointer;
}

.input-number-group .quantity-field {
  width: 5.3rem;
  height: 3.4rem;
  color: inherit;
  font-size: 1.3rem;
  font-family: inherit;
  padding: 0.8rem 1rem;
  // border: 1px solid #ddd;
  transition: all 0.2s;
  text-align: center;
  left: -6px;
  position: relative;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
}

.input-number-group .button-minus,
.input-number-group .button-plus {
  font-weight: bold;
  height: 3.4rem;
  padding: 0;
  width: 3.4rem;
  position: relative;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
}

.input-number-group .button-plus {
  left: -9.1px;
  border-radius: 0 0.8rem 0.8rem 0;
}

.input-number-group .button-minus {
  border-radius: 0.8rem 0 0 0.8rem;
}
